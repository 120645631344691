<template>
	<main>
		<div id="top" nitrohosting-id="S1Yc4ktoGy7lRV8o" class="nitrohosting-row nitrohosting-row-stretch-full nitrohosting-height-fit">
			<div class="nitrohosting-background-overlay"></div>
			<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-full">
				<div nitrohosting-id="J1y7IXxgvisoHaIG" class="nitrohosting-col nitrohosting-col-9">
					<div class="nitrohosting-col-holder">
						<div nitrohosting-id="39znwjlF0Th1Gcxc" class="nitrohosting-heading" style="text-align: left;">
							<div class="nitrohosting-heading-holder"> Better website with better hosting</div>
						</div>
						<div nitrohosting-id="nBaUhKmR7rkjxGZO" class="nitrohosting-heading" style="text-align: left;">
							<div class="nitrohosting-heading-holder"> Web Hosting</div>
						</div>
					</div>
				</div>
				<div nitrohosting-id="cO83NDDMWrjmrxk8" class="nitrohosting-col nitrohosting-col-3 nitrohosting-hide-mobile">
					<div class="nitrohosting-col-holder">
						<div nitrohosting-id="gDmxrsBHrCCRx4Bl" class="nitrohosting-btn nitrohosting-hide-mobile">
							<a v-scroll-to="'#pricing'" class="nitrohosting-btn-holder nitrohosting-ele-link nitrohosting-btn-custom nitrohosting-btn-small nitrohosting-btn-icon-right">
								<span class="nitrohosting-btn-text">View Plans</span>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div id="feature" nitrohosting-id="FCzMntp9Sb9AdEI6" class="nitrohosting-row nitrohosting-row-stretch-auto nitrohosting-height-default">
			<div class="nitrohosting-background-overlay"></div>
			<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
				<div nitrohosting-id="BpmDLtmttIDMUFlV" class="nitrohosting-col nitrohosting-col-6">
					<div class="nitrohosting-col-holder">
						<div v-scroll-reveal="{ origin: 'bottom' }" nitrohosting-id="HgcuORXpZge3eiiW" class="nitrohosting-heading" style="text-align: center;">
							<div class="nitrohosting-heading-holder"> Core Features</div>
						</div>
						<div v-scroll-reveal="{ origin: 'left' }" nitrohosting-id="NIUdxwsmHskryQDK" class="nitrohosting-divider">
							<div class="nitrohosting-divider-holder">
								<span class="nitrohosting-divider-separator"></span></div>
						</div>
						<div v-scroll-reveal="{ origin: 'left' }" nitrohosting-id="gR1Ik7BiUXGM2a1h" class="nitrohosting-inner_row nitrohosting-row-stretch-auto nitrohosting-height-default">
							<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
								<div nitrohosting-id="moggQgbzp4S0faWc" class="nitrohosting-col nitrohosting-col-7">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="ytntaaIBblDc3l9e" class="nitrohosting-heading">
											<div class="nitrohosting-heading-holder"> Complete Acceleration</div>
										</div>
										<div nitrohosting-id="hJGbcoFWqhpZK3Zx" class="nitrohosting-heading">
											<div class="nitrohosting-heading-holder"> LiteSpeed Web Server</div>
										</div>
										<div nitrohosting-id="UJZGv7tQS3sQRD8h" class="nitrohosting-text">
											<div class="nitrohosting-text-holder">
												<p>LiteSpeed has the unbeatable performance among all other web servers. Serving PHP 50% faster, up to 21x faster load speed with CloudLinux and our NVMe storage. LiteSpeed support HTTP/3 and QUIC. Perfect for both static and dynamic websites.</p>
											</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="cE7CLalYqizcXPgR" class="nitrohosting-col nitrohosting-col-5">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="13BS07Hg89HPWlst" class="nitrohosting-image" style="text-align: center;">
											<div class="nitrohosting-image-holder">
												<img class="nitrohosting-img" src="https://img.nitrohosting.pro/litespeed.svg" alt="LiteSpeed" />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-scroll-reveal="{ origin: 'right' }" nitrohosting-id="h3v5GwSZlS8b714x" class="nitrohosting-inner_row nitrohosting-row-stretch-auto nitrohosting-height-default">
							<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
								<div nitrohosting-id="iwMAVAHaJz2V23Ux" class="nitrohosting-col nitrohosting-col-5">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="5hyL2hXppuTSCJsT" class="nitrohosting-image" style="text-align: center;">
											<div class="nitrohosting-image-holder">
												<img class="nitrohosting-img" src="https://img.nitrohosting.pro/cpguard.svg" alt="cPGuard" />
											</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="KTGaUzpb4uE30bG9" class="nitrohosting-col nitrohosting-col-7">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="OpDJJDOySIvnxLY4" class="nitrohosting-heading">
											<div class="nitrohosting-heading-holder"> Automated Security</div>
										</div>
										<div nitrohosting-id="oyuooUlfqWccnSCz" class="nitrohosting-heading">
											<div class="nitrohosting-heading-holder"> cPGuard Security</div>
										</div>
										<div nitrohosting-id="6sTCR65xNCkCAY90" class="nitrohosting-text">
											<div class="nitrohosting-text-holder">
												<p>Our web servers use cPGuard for light, fast and intuitive protection against viruses and attacks. Offering powerful malware scanner with cloud-based auto clean. Complex intrusion detection and prevention keeping your website safe. Efficiently identifying security risks, hardening PHP components.</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-scroll-reveal="{ origin: 'left' }" nitrohosting-id="vNoJHo2QyPPulb3b" class="nitrohosting-inner_row nitrohosting-row-stretch-auto nitrohosting-height-default">
							<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
								<div nitrohosting-id="PcVNgHLgkQ6tN5j5" class="nitrohosting-col nitrohosting-col-7">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="rznBvRvS2jbiZKty" class="nitrohosting-heading">
											<div class="nitrohosting-heading-holder"> Smart Backup Solution</div>
										</div>
										<div nitrohosting-id="yMQ3SGy2rnTsDmPm" class="nitrohosting-heading">
											<div class="nitrohosting-heading-holder"> JetBackup Solution</div>
										</div>
										<div nitrohosting-id="8Koy7nUgIU9a8Pea" class="nitrohosting-text">
											<div class="nitrohosting-text-holder">
												<p>JetBackup is a fast, secure and reliable tool for backing up your files. We backup your account's files to external S3 servers daily. Their smart monitoring features allow us to organize your backups more efficiently. We only backup your new files and changed files to make sure the process is much smoother.<br />
												</p></div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="r9Z7oiQh9ybICTeB" class="nitrohosting-col nitrohosting-col-5">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="teuilNhqWwFG2Svf" class="nitrohosting-image" style="text-align: center;">
											<div class="nitrohosting-image-holder">
												<img class="nitrohosting-img" src="https://img.nitrohosting.pro/jetbackup.png" alt="JetBackup" />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-scroll-reveal="{ origin: 'right' }" nitrohosting-id="Up0qRcIepDar31PU" class="nitrohosting-inner_row nitrohosting-row-stretch-auto nitrohosting-height-default">
							<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
								<div nitrohosting-id="DBkd9n0I0OIxolYj" class="nitrohosting-col nitrohosting-col-5">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="EeWCuduDRuWxVQ2c" class="nitrohosting-image" style="text-align: center;">
											<div class="nitrohosting-image-holder">
												<img class="nitrohosting-img" src="https://img.nitrohosting.pro/sitepad.jpg" alt="SitePad" />
											</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="oxU75pQsLfUtxjpl" class="nitrohosting-col nitrohosting-col-7">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="Lexk9FUDDCClGo0C" class="nitrohosting-heading">
											<div class="nitrohosting-heading-holder"> Websites Without Coding</div>
										</div>
										<div nitrohosting-id="3rbWPu0RQb8eHF01" class="nitrohosting-heading">
											<div class="nitrohosting-heading-holder"> SitePad Web Builder</div>
										</div>
										<div nitrohosting-id="Yvt5r7dKaCuJJBJQ" class="nitrohosting-text">
											<div class="nitrohosting-text-holder">
												<p>SitePad Website Builder is an easy to use editor and publisher for static web pages. Zero coding knowledge required to create your own website. With rich featured widgets and over a thousand of templates, you can create a professional website in no time.</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div id="pricing" nitrohosting-id="D2mYtgaUjuiVDN29" class="nitrohosting-row nitrohosting-row-stretch-auto nitrohosting-height-default">
			<div class="nitrohosting-background-overlay"></div>
			<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
				<div nitrohosting-id="xaQFAp8pwnYbkBaO" class="nitrohosting-col nitrohosting-col-6">
					<div class="nitrohosting-col-holder">
						<div v-scroll-reveal="{ origin: 'bottom' }" nitrohosting-id="iFSQf0INUqisiGUS" class="nitrohosting-heading" style="text-align: center;">
							<div class="nitrohosting-heading-holder"> Plans and Pricing</div>
						</div>
						<div v-scroll-reveal="{ origin: 'left' }" nitrohosting-id="O11QsWW3KoviodcE" class="nitrohosting-divider">
							<div class="nitrohosting-divider-holder">
								<span class="nitrohosting-divider-separator"></span></div>
						</div>
						<div v-scroll-reveal="{ origin: 'bottom' }" nitrohosting-id="VoJR7LwnkpCr789P" class="nitrohosting-inner_row nitrohosting-row-stretch-auto nitrohosting-height-default">
							<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
								<div nitrohosting-id="7KCGIIb8K9ve0nyu" class="nitrohosting-col nitrohosting-col-4">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="s05dcsytgh79i9vx" class="nitrohosting-pricing">
											<div class="nitrohosting-pricing-details">
												<h3 class="nitrohosting-pricing-type">WEB-1</h3></div>
											<div class="nitrohosting-pricing-rate-section">
												<h2 class="nitrohosting-pricing-price">
													<span class="nitrohosting-pricing-currency nitrohosting-pricing-currency-top">$</span>
													<span class="nitrohosting-pricing-rate nitrohosting-pricing-amt">3</span>
												</h2>
												<p class="nitrohosting-pricing-duration">Per Month</p></div>
											<div class="nitrohosting-pricing-features">
												<ul class="nitrohosting-pricing-ul">
													<div nitrohosting-id="fk0jgr9yh9lqh3kv" class="nitrohosting-list_item">
														<li class="nitrohosting-list-li">
															<span class="nitrohosting-list-icon-holder"><span class="nitrohosting-list-item">5GB NVMe Storage</span> </span>
														</li>
													</div>
													<div nitrohosting-id="l19syi0gqqrt8svw" class="nitrohosting-list_item">
														<li class="nitrohosting-list-li">
															<span class="nitrohosting-list-icon-holder"><span class="nitrohosting-list-item">sFTP/FTP Access</span> </span>
														</li>
													</div>
													<div nitrohosting-id="xu7cmke23l88qo9p" class="nitrohosting-list_item">
														<li class="nitrohosting-list-li">
															<span class="nitrohosting-list-icon-holder"><span class="nitrohosting-list-item">1 SQL Database</span> </span>
														</li>
													</div>
													<div nitrohosting-id="2faj1joc9zgbt18p" class="nitrohosting-list_item">
														<li class="nitrohosting-list-li">
															<span class="nitrohosting-list-icon-holder"><span class="nitrohosting-list-item">Unmetered Bandwidth</span> </span>
														</li>
													</div>
													<div nitrohosting-id="0ud6xmcww56iso4f" class="nitrohosting-list_item">
														<li class="nitrohosting-list-li">
															<span class="nitrohosting-list-icon-holder"><span class="nitrohosting-list-item">1Gbps uplink</span> </span>
														</li>
													</div>
													<div nitrohosting-id="yhGsi6Ya87lYYltN" class="nitrohosting-list_item">
														<li class="nitrohosting-list-li">
															<span class="nitrohosting-list-icon-holder"><span class="nitrohosting-list-item">Instant Setup</span> </span>
														</li>
													</div>
													<div nitrohosting-id="14VG7Xff3gLZbIT0" class="nitrohosting-list_item">
														<li class="nitrohosting-list-li">
															<span class="nitrohosting-list-icon-holder"><span class="nitrohosting-list-item">DDoS Protection</span> </span>
														</li>
													</div>
													<div nitrohosting-id="4Aj1awY63jByQTtj" class="nitrohosting-list_item">
														<li class="nitrohosting-list-li">
															<span class="nitrohosting-list-icon-holder"><span class="nitrohosting-list-item">24/7 Support</span> </span>
														</li>
													</div>
												</ul>
												<a href="https://billing.nitrohosting.pro/cart.php?extra=0&amp;&amp;a=add&amp;pid=1&amp;currency=1&amp;billingcycle=monthly" class="nitrohosting-pricing-btn nitrohosting-btn-custom nitrohosting-ele-link nitrohosting-button nitrohosting-btn-small">Purchase Now</a>
												<p class="nitrohosting-pricing-additional">Suitable for starters / for testing</p>
											</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="ESyGY7i5IfNFJdIC" class="nitrohosting-col nitrohosting-col-4">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="cFtGbSJp3ND7xx1d" class="nitrohosting-pricing">
											<div class="nitrohosting-pricing-details">
												<h3 class="nitrohosting-pricing-type">WEB-2</h3></div>
											<div class="nitrohosting-pricing-rate-section">
												<h2 class="nitrohosting-pricing-price">
													<span class="nitrohosting-pricing-currency nitrohosting-pricing-currency-top">$</span>
													<span class="nitrohosting-pricing-rate nitrohosting-pricing-amt">6</span>
												</h2>
												<p class="nitrohosting-pricing-duration">Per Month</p></div>
											<div class="nitrohosting-pricing-features">
												<ul class="nitrohosting-pricing-ul">
													<div nitrohosting-id="39AMKrKsdJ2X2wbC" class="nitrohosting-list_item">
														<li class="nitrohosting-list-li">
															<span class="nitrohosting-list-icon-holder"><span class="nitrohosting-list-item">15GB NVMe Storage</span> </span>
														</li>
													</div>
													<div nitrohosting-id="SBjMEenkBxmlQtgq" class="nitrohosting-list_item">
														<li class="nitrohosting-list-li">
															<span class="nitrohosting-list-icon-holder"><span class="nitrohosting-list-item">sFTP/FTP Access</span> </span>
														</li>
													</div>
													<div nitrohosting-id="dhffe9uQjx6z4U0U" class="nitrohosting-list_item">
														<li class="nitrohosting-list-li">
															<span class="nitrohosting-list-icon-holder"><span class="nitrohosting-list-item">3 SQL Database</span> </span>
														</li>
													</div>
													<div nitrohosting-id="5b2rWZNufJOrK8kW" class="nitrohosting-list_item">
														<li class="nitrohosting-list-li">
															<span class="nitrohosting-list-icon-holder"><span class="nitrohosting-list-item">Unmetered Bandwidth</span> </span>
														</li>
													</div>
													<div nitrohosting-id="KRVXl4UNK3JOOMqu" class="nitrohosting-list_item">
														<li class="nitrohosting-list-li">
															<span class="nitrohosting-list-icon-holder"><span class="nitrohosting-list-item">1Gbps uplink</span> </span>
														</li>
													</div>
													<div nitrohosting-id="c7egd4M28iI7MPN7" class="nitrohosting-list_item">
														<li class="nitrohosting-list-li">
															<span class="nitrohosting-list-icon-holder"><span class="nitrohosting-list-item">Instant Setup</span> </span>
														</li>
													</div>
													<div nitrohosting-id="0aqpxKCyykp8NHJW" class="nitrohosting-list_item">
														<li class="nitrohosting-list-li">
															<span class="nitrohosting-list-icon-holder"><span class="nitrohosting-list-item">DDoS Protection</span> </span>
														</li>
													</div>
													<div nitrohosting-id="RlBIYu6okq2uvryH" class="nitrohosting-list_item">
														<li class="nitrohosting-list-li">
															<span class="nitrohosting-list-icon-holder"><span class="nitrohosting-list-item">24/7 Support</span> </span>
														</li>
													</div>
												</ul>
												<a href="https://billing.nitrohosting.pro/cart.php?extra=0&amp;&amp;a=add&amp;pid=3&amp;currency=1&amp;billingcycle=monthly" class="nitrohosting-pricing-btn nitrohosting-btn-custom nitrohosting-ele-link nitrohosting-button nitrohosting-btn-small">Purchase Now</a>
												<p class="nitrohosting-pricing-additional">Suitable for small and medium websites</p>
											</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="uaoJRzyXlKgRlnEX" class="nitrohosting-col nitrohosting-col-4">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="29zPUzrPtKFLsb5y" class="nitrohosting-pricing">
											<div class="nitrohosting-pricing-details">
												<h3 class="nitrohosting-pricing-type">WEB-3</h3></div>
											<div class="nitrohosting-pricing-rate-section">
												<h2 class="nitrohosting-pricing-price">
													<span class="nitrohosting-pricing-currency nitrohosting-pricing-currency-top">$</span>
													<span class="nitrohosting-pricing-rate nitrohosting-pricing-amt">10</span>
												</h2>
												<p class="nitrohosting-pricing-duration">Per Month</p></div>
											<div class="nitrohosting-pricing-features">
												<ul class="nitrohosting-pricing-ul">
													<div nitrohosting-id="Dr4KBZKm7Rp8yTJG" class="nitrohosting-list_item">
														<li class="nitrohosting-list-li">
															<span class="nitrohosting-list-icon-holder"><span class="nitrohosting-list-item">30GB NVMe Storage</span> </span>
														</li>
													</div>
													<div nitrohosting-id="CbsjfloYWeuniIi9" class="nitrohosting-list_item">
														<li class="nitrohosting-list-li">
															<span class="nitrohosting-list-icon-holder"><span class="nitrohosting-list-item">sFTP/FTP Access</span> </span>
														</li>
													</div>
													<div nitrohosting-id="pyOiC9NmydEA541c" class="nitrohosting-list_item">
														<li class="nitrohosting-list-li">
															<span class="nitrohosting-list-icon-holder"><span class="nitrohosting-list-item">5 SQL Database</span> </span>
														</li>
													</div>
													<div nitrohosting-id="7nIEORmyN0BSE8w7" class="nitrohosting-list_item">
														<li class="nitrohosting-list-li">
															<span class="nitrohosting-list-icon-holder"><span class="nitrohosting-list-item">Unmetered Bandwidth</span> </span>
														</li>
													</div>
													<div nitrohosting-id="WATnYGeWB6Idt3GM" class="nitrohosting-list_item">
														<li class="nitrohosting-list-li">
															<span class="nitrohosting-list-icon-holder"><span class="nitrohosting-list-item">1Gbps uplink</span> </span>
														</li>
													</div>
													<div nitrohosting-id="MfmTlqc3xvE13NYl" class="nitrohosting-list_item">
														<li class="nitrohosting-list-li">
															<span class="nitrohosting-list-icon-holder"><span class="nitrohosting-list-item">Instant Setup</span> </span>
														</li>
													</div>
													<div nitrohosting-id="9YYQk6yxnJZyUs29" class="nitrohosting-list_item">
														<li class="nitrohosting-list-li">
															<span class="nitrohosting-list-icon-holder"><span class="nitrohosting-list-item">DDoS Protection</span> </span>
														</li>
													</div>
													<div nitrohosting-id="O79zez8DC99Q5Ce0" class="nitrohosting-list_item">
														<li class="nitrohosting-list-li">
															<span class="nitrohosting-list-icon-holder"><span class="nitrohosting-list-item">24/7 Support</span> </span>
														</li>
													</div>
												</ul>
												<a href="https://billing.nitrohosting.pro/cart.php?extra=0&amp;&amp;a=add&amp;pid=2&amp;currency=1&amp;billingcycle=monthly" class="nitrohosting-pricing-btn nitrohosting-btn-custom nitrohosting-ele-link nitrohosting-button nitrohosting-btn-small">Purchase Now</a>
												<p class="nitrohosting-pricing-additional">Suitable for medium websites (High volume)</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-scroll-reveal="{ origin: 'bottom' }" nitrohosting-id="IpF91BjEb465eBaZ" class="nitrohosting-inner_row nitrohosting-row-stretch-auto nitrohosting-height-default">
							<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
								<div nitrohosting-id="yjrWL26aYOAIUNIQ" class="nitrohosting-col nitrohosting-col-4">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="3iK4Mq4qKGBjCuXW" class="nitrohosting-pricing">
											<div class="nitrohosting-pricing-details">
												<h3 class="nitrohosting-pricing-type">WEB-4</h3></div>
											<div class="nitrohosting-pricing-rate-section">
												<h2 class="nitrohosting-pricing-price">
													<span class="nitrohosting-pricing-currency nitrohosting-pricing-currency-top">$</span>
													<span class="nitrohosting-pricing-rate nitrohosting-pricing-amt">18</span>
												</h2>
												<p class="nitrohosting-pricing-duration">Per Month</p></div>
											<div class="nitrohosting-pricing-features">
												<ul class="nitrohosting-pricing-ul">
													<div nitrohosting-id="lk5kFWZs1vxC6UFv" class="nitrohosting-list_item">
														<li class="nitrohosting-list-li">
															<span class="nitrohosting-list-icon-holder"><span class="nitrohosting-list-item">100GB NVMe Storage</span> </span>
														</li>
													</div>
													<div nitrohosting-id="2ZFBozVJmY9mJGkA" class="nitrohosting-list_item">
														<li class="nitrohosting-list-li">
															<span class="nitrohosting-list-icon-holder"><span class="nitrohosting-list-item">sFTP/FTP Access</span> </span>
														</li>
													</div>
													<div nitrohosting-id="uPTlHkD8ypn3htse" class="nitrohosting-list_item">
														<li class="nitrohosting-list-li">
															<span class="nitrohosting-list-icon-holder"><span class="nitrohosting-list-item">10 SQL Database</span> </span>
														</li>
													</div>
													<div nitrohosting-id="V4W8QUk49qcqeTYs" class="nitrohosting-list_item">
														<li class="nitrohosting-list-li">
															<span class="nitrohosting-list-icon-holder"><span class="nitrohosting-list-item">Unlimited Bandwidth</span> </span>
														</li>
													</div>
													<div nitrohosting-id="PR91JOwyYRvBylmn" class="nitrohosting-list_item">
														<li class="nitrohosting-list-li">
															<span class="nitrohosting-list-icon-holder"><span class="nitrohosting-list-item">1Gbps uplink</span> </span>
														</li>
													</div>
													<div nitrohosting-id="OY5rzoafPeUcjVaq" class="nitrohosting-list_item">
														<li class="nitrohosting-list-li">
															<span class="nitrohosting-list-icon-holder"><span class="nitrohosting-list-item">Instant Setup</span> </span>
														</li>
													</div>
													<div nitrohosting-id="O4yS48J60Yx1gaSs" class="nitrohosting-list_item">
														<li class="nitrohosting-list-li">
															<span class="nitrohosting-list-icon-holder"><span class="nitrohosting-list-item">DDoS Protection</span> </span>
														</li>
													</div>
													<div nitrohosting-id="mzv0dhKusuacT3Xp" class="nitrohosting-list_item">
														<li class="nitrohosting-list-li">
															<span class="nitrohosting-list-icon-holder"><span class="nitrohosting-list-item">24/7 Support</span> </span>
														</li>
													</div>
												</ul>
												<a href="https://billing.nitrohosting.pro/cart.php?extra=0&amp;&amp;a=add&amp;pid=4&amp;currency=1&amp;billingcycle=monthly" class="nitrohosting-pricing-btn nitrohosting-btn-custom nitrohosting-ele-link nitrohosting-button nitrohosting-btn-small">Purchase Now</a>
												<p class="nitrohosting-pricing-additional">Suitable for large websites</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div id="compare" nitrohosting-id="8hSO7dHDYm2rX6UZ" class="nitrohosting-row nitrohosting-row-stretch-auto nitrohosting-height-default">
			<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
				<div nitrohosting-id="9JkKBUZxMbiA6Owr" class="nitrohosting-col nitrohosting-col-12">
					<div class="nitrohosting-col-holder">
						<div v-scroll-reveal="{ origin: 'bottom' }" nitrohosting-id="zLjtZzfKeoQAIyIa" class="nitrohosting-heading" style="text-align: center;">
							<div class="nitrohosting-heading-holder"> Comparison</div>
						</div>
						<div v-scroll-reveal="{ origin: 'left' }" nitrohosting-id="h2DvnXSQG5H8C1mK" class="nitrohosting-divider">
							<div class="nitrohosting-divider-holder">
								<span class="nitrohosting-divider-separator"></span></div>
						</div>
						<div v-scroll-reveal="{ origin: 'bottom' }" nitrohosting-id="tPFskh7FIw85wlka" class="nitrohosting-inner_row nitrohosting-row-stretch-auto nitrohosting-height-default" style="visibility: visible; animation-name: fadeInUp;">
							<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
								<div nitrohosting-id="ayWTcvvwAhyCZuEo" class="nitrohosting-col">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="d8wdSHRm6R3EhjrV" class="nitrohosting-table">
											<div class="nitrohosting-data-holder">
												<div nitrohosting-id="ozQVjpxEkUvIQ4yT" class="nitrohosting-table_row">
													<div class="nitrohosting-table-row-holder">
														<div nitrohosting-id="nO3wI9wFbdHLc9be" class="nitrohosting-table_col">
															<div data-td="" data-tag="td" class="nitrohosting-col-data"></div>
														</div>
														<div nitrohosting-id="2UhNx1Z0MlIULTzo" class="nitrohosting-table_col">
															<div data-td="NitroHosting" data-tag="td" class="nitrohosting-col-data"></div>
														</div>
														<div nitrohosting-id="mRtTpuNvQtfRRYj9" class="nitrohosting-table_col">
															<div data-td="HostGator" data-tag="td" class="nitrohosting-col-data"></div>
														</div>
														<div nitrohosting-id="7t6hWqc0PWPn6DzM" class="nitrohosting-table_col">
															<div data-td="GoDaddy" data-tag="td" class="nitrohosting-col-data"></div>
														</div>
														<div nitrohosting-id="WSugnhLkfKxV2A30" class="nitrohosting-table_col">
															<div data-td="SiteGround" data-tag="td" class="nitrohosting-col-data"></div>
														</div>
													</div>
												</div>
												<div nitrohosting-id="7UKKqZQZAur4ewxs" class="nitrohosting-table_row">
													<div class="nitrohosting-table-row-holder">
														<div nitrohosting-id="HKboMOTU6h1YIGpn" class="nitrohosting-table_col">
															<div data-td="Regular Price" data-tag="td" class="nitrohosting-col-data"></div>
														</div>
														<div nitrohosting-id="ONTBy2WobjfwKDvi" class="nitrohosting-table_col">
															<div data-td="$3/mo" data-tag="td" class="nitrohosting-col-data"></div>
														</div>
														<div nitrohosting-id="g28DpWyhNScKyIyi" class="nitrohosting-table_col">
															<div data-td="$10.95/mo" data-tag="td" class="nitrohosting-col-data"></div>
														</div>
														<div nitrohosting-id="JiHVeaTjRVsmOimv" class="nitrohosting-table_col">
															<div data-td="$8.99/mo" data-tag="td" class="nitrohosting-col-data"></div>
														</div>
														<div nitrohosting-id="5ynZ0UlAskHg6CwZ" class="nitrohosting-table_col">
															<div data-td="$11.95/mo" data-tag="td" class="nitrohosting-col-data"></div>
														</div>
													</div>
												</div>
												<div nitrohosting-id="ziJDK1DQaVLtnoTW" class="nitrohosting-table_row">
													<div class="nitrohosting-table-row-holder">
														<div nitrohosting-id="nv0eA6Xct8zGQgMU" class="nitrohosting-table_col">
															<div data-td="Storage" data-tag="td" class="nitrohosting-col-data"></div>
														</div>
														<div nitrohosting-id="WvRHjcQp1S4aUz5h" class="nitrohosting-table_col">
															<div data-td="NVMe RAID10" data-tag="td" class="nitrohosting-col-data"></div>
														</div>
														<div nitrohosting-id="dtpoqEjtGxZwcVKp" class="nitrohosting-table_col">
															<div data-td="HDD RAID1" data-tag="td" class="nitrohosting-col-data"></div>
														</div>
														<div nitrohosting-id="yL62OwLGPfzLFTgq" class="nitrohosting-table_col">
															<div data-td="HDD" data-tag="td" class="nitrohosting-col-data"></div>
														</div>
														<div nitrohosting-id="JP260eiUghzb46rj" class="nitrohosting-table_col">
															<div data-td="SSD RAID10" data-tag="td" class="nitrohosting-col-data"></div>
														</div>
													</div>
												</div>
												<div nitrohosting-id="TuLoqq7M3eOBqrmk" class="nitrohosting-table_row">
													<div class="nitrohosting-table-row-holder">
														<div nitrohosting-id="MhaYzRRMhlGN8IzF" class="nitrohosting-table_col">
															<div data-td="Bandwidth" data-tag="td" class="nitrohosting-col-data"></div>
														</div>
														<div nitrohosting-id="2fofcJb5arSIBftU" class="nitrohosting-table_col">
															<div data-td="Unmetered" data-tag="td" class="nitrohosting-col-data"></div>
														</div>
														<div nitrohosting-id="gvgDCWnxBC2rKSdU" class="nitrohosting-table_col">
															<div data-td="Unmetered" data-tag="td" class="nitrohosting-col-data"></div>
														</div>
														<div nitrohosting-id="36K6GNPlKnkXZ6n0" class="nitrohosting-table_col">
															<div data-td="Unmetered" data-tag="td" class="nitrohosting-col-data"></div>
														</div>
														<div nitrohosting-id="XcdUj3FrKrNElfOA" class="nitrohosting-table_col">
															<div data-td="Limited Visits" data-tag="td" class="nitrohosting-col-data"></div>
														</div>
													</div>
												</div>
												<div nitrohosting-id="VjyBBFlEkyaNYryC" class="nitrohosting-table_row">
													<div class="nitrohosting-table-row-holder">
														<div nitrohosting-id="vQslWjeegBUIMmqx" class="nitrohosting-table_col">
															<div data-td="LiteSpeed Web Server" data-tag="td" class="nitrohosting-col-data"></div>
														</div>
														<div nitrohosting-id="cL870tNXtIXbVtGP" class="nitrohosting-table_col">
															<div data-td="<i class=&quot;fas fa-check&quot;></i>" data-tag="td" class="nitrohosting-col-data"></div>
														</div>
														<div nitrohosting-id="AP8ffOA12Lam5eBI" class="nitrohosting-table_col">
															<div data-td="<i class=&quot;fas fa-times&quot;></i>" data-tag="td" class="nitrohosting-col-data"></div>
														</div>
														<div nitrohosting-id="HV3FcmQITGr8Hrkd" class="nitrohosting-table_col">
															<div data-td="<i class=&quot;fas fa-times&quot;></i>" data-tag="td" class="nitrohosting-col-data"></div>
														</div>
														<div nitrohosting-id="9zTxWeyZkXc8uzBq" class="nitrohosting-table_col">
															<div data-td="<i class=&quot;fas fa-check&quot;></i>" data-tag="td" class="nitrohosting-col-data"></div>
														</div>
													</div>
												</div>
												<div nitrohosting-id="2zY7kmEWsGQ0rPP5" class="nitrohosting-table_row">
													<div class="nitrohosting-table-row-holder">
														<div nitrohosting-id="7lU8BL8RVqdLbpLi" class="nitrohosting-table_col">
															<div data-td="HTTP/3 &amp; QUIC Support" data-tag="td" class="nitrohosting-col-data"></div>
														</div>
														<div nitrohosting-id="Lz3HB1Gw2TeBpMOW" class="nitrohosting-table_col">
															<div data-td="<i class=&quot;fas fa-check&quot;></i>" data-tag="td" class="nitrohosting-col-data"></div>
														</div>
														<div nitrohosting-id="4ubZ42hP9eD70ULe" class="nitrohosting-table_col">
															<div data-td="<i class=&quot;fas fa-times&quot;></i>" data-tag="td" class="nitrohosting-col-data"></div>
														</div>
														<div nitrohosting-id="UruwkUUtfAoQ1zJa" class="nitrohosting-table_col">
															<div data-td="<i class=&quot;fas fa-times&quot;></i>" data-tag="td" class="nitrohosting-col-data"></div>
														</div>
														<div nitrohosting-id="nvfYAlC5iGxOgNft" class="nitrohosting-table_col">
															<div data-td="<i class=&quot;fas fa-check&quot;></i>" data-tag="td" class="nitrohosting-col-data"></div>
														</div>
													</div>
												</div>
												<div nitrohosting-id="BreXb7DfDNZYntgg" class="nitrohosting-table_row">
													<div class="nitrohosting-table-row-holder">
														<div nitrohosting-id="JgscOeBsANE2goKC" class="nitrohosting-table_col">
															<div data-td="Free Backup" data-tag="td" class="nitrohosting-col-data"></div>
														</div>
														<div nitrohosting-id="XLIYwqp50GjLPcZr" class="nitrohosting-table_col">
															<div data-td="Daily" data-tag="td" class="nitrohosting-col-data"></div>
														</div>
														<div nitrohosting-id="9Da2HeC65bTXWNgT" class="nitrohosting-table_col">
															<div data-td="Weekly" data-tag="td" class="nitrohosting-col-data"></div>
														</div>
														<div nitrohosting-id="bmtnLTv6HfIVHj1U" class="nitrohosting-table_col">
															<div data-td="<i class=&quot;fas fa-times&quot;></i>" data-tag="td" class="nitrohosting-col-data"></div>
														</div>
														<div nitrohosting-id="tvDhC6Fdiub5WVtH" class="nitrohosting-table_col">
															<div data-td="Daily" data-tag="td" class="nitrohosting-col-data"></div>
														</div>
													</div>
												</div>
												<div nitrohosting-id="O2D8Wy8F0CxZdcLk" class="nitrohosting-table_row">
													<div class="nitrohosting-table-row-holder">
														<div nitrohosting-id="yXG5NiKlA8QVHkR9" class="nitrohosting-table_col">
															<div data-td="256-Bit SSL Certificate" data-tag="td" class="nitrohosting-col-data"></div>
														</div>
														<div nitrohosting-id="5exmLlHM8c5TrPAk" class="nitrohosting-table_col">
															<div data-td="Free" data-tag="td" class="nitrohosting-col-data"></div>
														</div>
														<div nitrohosting-id="3Ope9qohtE6othc8" class="nitrohosting-table_col">
															<div data-td="Free" data-tag="td" class="nitrohosting-col-data"></div>
														</div>
														<div nitrohosting-id="OZFIivIsKo22t1eb" class="nitrohosting-table_col">
															<div data-td="Paid" data-tag="td" class="nitrohosting-col-data"></div>
														</div>
														<div nitrohosting-id="Lo2lopObPTuCYwv1" class="nitrohosting-table_col">
															<div data-td="Free" data-tag="td" class="nitrohosting-col-data"></div>
														</div>
													</div>
												</div>
												<div nitrohosting-id="uQIF06kdMj2nCvAI" class="nitrohosting-table_row">
													<div class="nitrohosting-table-row-holder">
														<div nitrohosting-id="NFpwV7MjqCIMsfEx" class="nitrohosting-table_col">
															<div data-td="Advanced Malware Protection" data-tag="td" class="nitrohosting-col-data"></div>
														</div>
														<div nitrohosting-id="aaeimvwXk4lKBMaP" class="nitrohosting-table_col">
															<div data-td="<i class=&quot;fas fa-check&quot;></i>" data-tag="td" class="nitrohosting-col-data"></div>
														</div>
														<div nitrohosting-id="8Vfjmas070Be5AdB" class="nitrohosting-table_col">
															<div data-td="<i class=&quot;fas fa-times&quot;></i>" data-tag="td" class="nitrohosting-col-data"></div>
														</div>
														<div nitrohosting-id="J9mCHla1y7NbMVEz" class="nitrohosting-table_col">
															<div data-td="Scan Only" data-tag="td" class="nitrohosting-col-data"></div>
														</div>
														<div nitrohosting-id="zq2vv99tC8xWKcWn" class="nitrohosting-table_col">
															<div data-td="<i class=&quot;fas fa-check&quot;></i>" data-tag="td" class="nitrohosting-col-data"></div>
														</div>
													</div>
												</div>
												<div nitrohosting-id="ajAgHoyjYBuMSdsZ" class="nitrohosting-table_row">
													<div class="nitrohosting-table-row-holder">
														<div nitrohosting-id="OWjeakWm0A93xmFu" class="nitrohosting-table_col">
															<div data-td="Free Website Builder" data-tag="td" class="nitrohosting-col-data"></div>
														</div>
														<div nitrohosting-id="37CVe6o6OdbsbVN2" class="nitrohosting-table_col">
															<div data-td="SitePad" data-tag="td" class="nitrohosting-col-data"></div>
														</div>
														<div nitrohosting-id="ewMM5QhY3KmIFqmT" class="nitrohosting-table_col">
															<div data-td="HWB" data-tag="td" class="nitrohosting-col-data"></div>
														</div>
														<div nitrohosting-id="tckRdYoJPdaVkjId" class="nitrohosting-table_col">
															<div data-td="<i class=&quot;fas fa-times&quot;></i>" data-tag="td" class="nitrohosting-col-data"></div>
														</div>
														<div nitrohosting-id="DAqjrIGs6iCGDti4" class="nitrohosting-table_col">
															<div data-td="<i class=&quot;fas fa-times&quot;></i>" data-tag="td" class="nitrohosting-col-data"></div>
														</div>
													</div>
												</div>
												<div nitrohosting-id="jNHzlwIjRHKENWEC" class="nitrohosting-table_row">
													<div class="nitrohosting-table-row-holder">
														<div nitrohosting-id="i5wBlDHV6d6qW4ki" class="nitrohosting-table_col">
															<div data-td="Free Website Migration" data-tag="td" class="nitrohosting-col-data"></div>
														</div>
														<div nitrohosting-id="gatqhhCvwxm8KT3r" class="nitrohosting-table_col">
															<div data-td="<i class=&quot;fas fa-check&quot;></i>" data-tag="td" class="nitrohosting-col-data"></div>
														</div>
														<div nitrohosting-id="70RA6eGSWh3uqT6f" class="nitrohosting-table_col">
															<div data-td="New Account Only" data-tag="td" class="nitrohosting-col-data"></div>
														</div>
														<div nitrohosting-id="fORY1V35EyQCAKSw" class="nitrohosting-table_col">
															<div data-td="<i class=&quot;fas fa-check&quot;></i>" data-tag="td" class="nitrohosting-col-data"></div>
														</div>
														<div nitrohosting-id="kU88f9H3UxMOfgfl" class="nitrohosting-table_col">
															<div data-td="$30/site" data-tag="td" class="nitrohosting-col-data"></div>
														</div>
													</div>
												</div>
												<div nitrohosting-id="YISDL0h5rfwFFqoy" class="nitrohosting-table_row">
													<div class="nitrohosting-table-row-holder">
														<div nitrohosting-id="XJAXWVIxamEirKyl" class="nitrohosting-table_col">
															<div data-td="Premium Network Connection" data-tag="td" class="nitrohosting-col-data"></div>
														</div>
														<div nitrohosting-id="ZBT4MxGj9gafdq5R" class="nitrohosting-table_col">
															<div data-td="1Gbps" data-tag="td" class="nitrohosting-col-data"></div>
														</div>
														<div nitrohosting-id="Igx3EM2ZttUvJlVO" class="nitrohosting-table_col">
															<div data-td="100Mbps" data-tag="td" class="nitrohosting-col-data"></div>
														</div>
														<div nitrohosting-id="SATXqlUdnQ52LSKs" class="nitrohosting-table_col">
															<div data-td="100Mbps" data-tag="td" class="nitrohosting-col-data"></div>
														</div>
														<div nitrohosting-id="5ROENIe4zeOMWx6a" class="nitrohosting-table_col">
															<div data-td="1Gbps" data-tag="td" class="nitrohosting-col-data"></div>
														</div>
													</div>
												</div>
											</div>
											<table class="nitrohosting-table-holder">
												<tr nitrohosting-table-id="ozQVjpxEkUvIQ4yT">
													<td colspan="undefined" rowspan="undefined" nitrohosting-table-id="nO3wI9wFbdHLc9be"></td>
													<td colspan="undefined" rowspan="undefined" nitrohosting-table-id="2UhNx1Z0MlIULTzo">NitroHosting</td>
													<td colspan="undefined" rowspan="undefined" nitrohosting-table-id="mRtTpuNvQtfRRYj9">HostGator</td>
													<td colspan="undefined" rowspan="undefined" nitrohosting-table-id="7t6hWqc0PWPn6DzM">GoDaddy</td>
													<td colspan="undefined" rowspan="undefined" nitrohosting-table-id="WSugnhLkfKxV2A30">SiteGround</td>
												</tr>
												<tr nitrohosting-table-id="7UKKqZQZAur4ewxs">
													<td colspan="undefined" rowspan="undefined" nitrohosting-table-id="HKboMOTU6h1YIGpn">Regular Price</td>
													<td colspan="undefined" rowspan="undefined" nitrohosting-table-id="ONTBy2WobjfwKDvi">$3/mo</td>
													<td colspan="undefined" rowspan="undefined" nitrohosting-table-id="g28DpWyhNScKyIyi">$10.95/mo</td>
													<td colspan="undefined" rowspan="undefined" nitrohosting-table-id="JiHVeaTjRVsmOimv">$8.99/mo</td>
													<td colspan="undefined" rowspan="undefined" nitrohosting-table-id="5ynZ0UlAskHg6CwZ">$11.95/mo</td>
												</tr>
												<tr nitrohosting-table-id="ziJDK1DQaVLtnoTW">
													<td colspan="undefined" rowspan="undefined" nitrohosting-table-id="nv0eA6Xct8zGQgMU">Storage</td>
													<td colspan="undefined" rowspan="undefined" nitrohosting-table-id="WvRHjcQp1S4aUz5h">NVMe RAID10</td>
													<td colspan="undefined" rowspan="undefined" nitrohosting-table-id="dtpoqEjtGxZwcVKp">HDD RAID1</td>
													<td colspan="undefined" rowspan="undefined" nitrohosting-table-id="yL62OwLGPfzLFTgq">HDD</td>
													<td colspan="undefined" rowspan="undefined" nitrohosting-table-id="JP260eiUghzb46rj">SSD RAID10</td>
												</tr>
												<tr nitrohosting-table-id="TuLoqq7M3eOBqrmk">
													<td colspan="undefined" rowspan="undefined" nitrohosting-table-id="MhaYzRRMhlGN8IzF">Bandwidth</td>
													<td colspan="undefined" rowspan="undefined" nitrohosting-table-id="2fofcJb5arSIBftU">Unmetered</td>
													<td colspan="undefined" rowspan="undefined" nitrohosting-table-id="gvgDCWnxBC2rKSdU">Unmetered</td>
													<td colspan="undefined" rowspan="undefined" nitrohosting-table-id="36K6GNPlKnkXZ6n0">Unmetered</td>
													<td colspan="undefined" rowspan="undefined" nitrohosting-table-id="XcdUj3FrKrNElfOA">Limited Visits</td>
												</tr>
												<tr nitrohosting-table-id="VjyBBFlEkyaNYryC">
													<td colspan="undefined" rowspan="undefined" nitrohosting-table-id="vQslWjeegBUIMmqx">LiteSpeed Web Server</td>
													<td colspan="undefined" rowspan="undefined" nitrohosting-table-id="cL870tNXtIXbVtGP">
														<i class="fas fa-check"></i></td>
													<td colspan="undefined" rowspan="undefined" nitrohosting-table-id="AP8ffOA12Lam5eBI">
														<i class="fas fa-times"></i></td>
													<td colspan="undefined" rowspan="undefined" nitrohosting-table-id="HV3FcmQITGr8Hrkd">
														<i class="fas fa-times"></i></td>
													<td colspan="undefined" rowspan="undefined" nitrohosting-table-id="9zTxWeyZkXc8uzBq">
														<i class="fas fa-check"></i></td>
												</tr>
												<tr nitrohosting-table-id="2zY7kmEWsGQ0rPP5">
													<td colspan="undefined" rowspan="undefined" nitrohosting-table-id="7lU8BL8RVqdLbpLi">HTTP/3 &amp; QUIC Support</td>
													<td colspan="undefined" rowspan="undefined" nitrohosting-table-id="Lz3HB1Gw2TeBpMOW">
														<i class="fas fa-check"></i></td>
													<td colspan="undefined" rowspan="undefined" nitrohosting-table-id="4ubZ42hP9eD70ULe">
														<i class="fas fa-times"></i></td>
													<td colspan="undefined" rowspan="undefined" nitrohosting-table-id="UruwkUUtfAoQ1zJa">
														<i class="fas fa-times"></i></td>
													<td colspan="undefined" rowspan="undefined" nitrohosting-table-id="nvfYAlC5iGxOgNft">
														<i class="fas fa-check"></i></td>
												</tr>
												<tr nitrohosting-table-id="BreXb7DfDNZYntgg">
													<td colspan="undefined" rowspan="undefined" nitrohosting-table-id="JgscOeBsANE2goKC">Free Backup</td>
													<td colspan="undefined" rowspan="undefined" nitrohosting-table-id="XLIYwqp50GjLPcZr">Daily</td>
													<td colspan="undefined" rowspan="undefined" nitrohosting-table-id="9Da2HeC65bTXWNgT">Weekly</td>
													<td colspan="undefined" rowspan="undefined" nitrohosting-table-id="bmtnLTv6HfIVHj1U">
														<i class="fas fa-times"></i></td>
													<td colspan="undefined" rowspan="undefined" nitrohosting-table-id="tvDhC6Fdiub5WVtH">Daily</td>
												</tr>
												<tr nitrohosting-table-id="O2D8Wy8F0CxZdcLk">
													<td colspan="undefined" rowspan="undefined" nitrohosting-table-id="yXG5NiKlA8QVHkR9">256-Bit SSL Certificate</td>
													<td colspan="undefined" rowspan="undefined" nitrohosting-table-id="5exmLlHM8c5TrPAk">Free</td>
													<td colspan="undefined" rowspan="undefined" nitrohosting-table-id="3Ope9qohtE6othc8">Free</td>
													<td colspan="undefined" rowspan="undefined" nitrohosting-table-id="OZFIivIsKo22t1eb">Paid</td>
													<td colspan="undefined" rowspan="undefined" nitrohosting-table-id="Lo2lopObPTuCYwv1">Free</td>
												</tr>
												<tr nitrohosting-table-id="uQIF06kdMj2nCvAI">
													<td colspan="undefined" rowspan="undefined" nitrohosting-table-id="NFpwV7MjqCIMsfEx">Advanced Malware Protection</td>
													<td colspan="undefined" rowspan="undefined" nitrohosting-table-id="aaeimvwXk4lKBMaP">
														<i class="fas fa-check"></i></td>
													<td colspan="undefined" rowspan="undefined" nitrohosting-table-id="8Vfjmas070Be5AdB">
														<i class="fas fa-times"></i></td>
													<td colspan="undefined" rowspan="undefined" nitrohosting-table-id="J9mCHla1y7NbMVEz">Scan Only</td>
													<td colspan="undefined" rowspan="undefined" nitrohosting-table-id="zq2vv99tC8xWKcWn">
														<i class="fas fa-check"></i></td>
												</tr>
												<tr nitrohosting-table-id="ajAgHoyjYBuMSdsZ">
													<td colspan="undefined" rowspan="undefined" nitrohosting-table-id="OWjeakWm0A93xmFu">Free Website Builder</td>
													<td colspan="undefined" rowspan="undefined" nitrohosting-table-id="37CVe6o6OdbsbVN2">SitePad</td>
													<td colspan="undefined" rowspan="undefined" nitrohosting-table-id="ewMM5QhY3KmIFqmT">HWB</td>
													<td colspan="undefined" rowspan="undefined" nitrohosting-table-id="tckRdYoJPdaVkjId">
														<i class="fas fa-times"></i></td>
													<td colspan="undefined" rowspan="undefined" nitrohosting-table-id="DAqjrIGs6iCGDti4">
														<i class="fas fa-times"></i></td>
												</tr>
												<tr nitrohosting-table-id="jNHzlwIjRHKENWEC">
													<td colspan="undefined" rowspan="undefined" nitrohosting-table-id="i5wBlDHV6d6qW4ki">Free Website Migration</td>
													<td colspan="undefined" rowspan="undefined" nitrohosting-table-id="gatqhhCvwxm8KT3r">
														<i class="fas fa-check"></i></td>
													<td colspan="undefined" rowspan="undefined" nitrohosting-table-id="70RA6eGSWh3uqT6f">New Account Only</td>
													<td colspan="undefined" rowspan="undefined" nitrohosting-table-id="fORY1V35EyQCAKSw">
														<i class="fas fa-check"></i></td>
													<td colspan="undefined" rowspan="undefined" nitrohosting-table-id="kU88f9H3UxMOfgfl">$30/site</td>
												</tr>
												<tr nitrohosting-table-id="YISDL0h5rfwFFqoy">
													<td colspan="undefined" rowspan="undefined" nitrohosting-table-id="XJAXWVIxamEirKyl">Premium Network Connection</td>
													<td colspan="undefined" rowspan="undefined" nitrohosting-table-id="ZBT4MxGj9gafdq5R">1Gbps</td>
													<td colspan="undefined" rowspan="undefined" nitrohosting-table-id="Igx3EM2ZttUvJlVO">100Mbps</td>
													<td colspan="undefined" rowspan="undefined" nitrohosting-table-id="SATXqlUdnQ52LSKs">100Mbps</td>
													<td colspan="undefined" rowspan="undefined" nitrohosting-table-id="5ROENIe4zeOMWx6a">1Gbps</td>
												</tr>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>
<script>
	export default {
		name: "Web",
		metaInfo: {
			title: "Web Hosting",
			titleTemplate: "%s - NitroHosting"
		}
	};

	let header = document.querySelector(".nitrohosting-header > .nitrohosting-row");
	header.classList.remove("is-fixed");
</script>
<style scoped>
	@import "../assets/css/pages/Web.css";
</style>